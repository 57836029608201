
import PostsTaxonomiesService from "@/modules/posts-taxonomies/posts-taxonomies-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.create(payload).then(response => {
                resolve({
                    message: "PostsTaxonomies criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "PostsTaxonomies atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.remove(id).then(response => {
                resolve({
                    message: "PostsTaxonomies removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.delete(id).then(response => {
                resolve({
                    message: "PostsTaxonomies deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.get(id).then(response => {
                resolve({
                    message: "PostsTaxonomies encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.paginate(query).then(response => {
                resolve({
                    message: "PostsTaxonomies encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            PostsTaxonomiesService.all().then(response => {
                resolve({
                    message: "PostsTaxonomies encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsTaxonomies",
                    success: false,
                    error
                });
            })
        })
    }
}
